import React, { useState, useEffect, useRef } from 'react'
import {
  Button,
  Divider,
  Spin
} from 'antd'
import { postSendAcceptance } from '../../services/Investigation'
import { useParams } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import Notification from '../../services/Notification'

import SkeletonLoading from '../../component/SkeletonLoading'
const App = (props) => {
  const { token } = useParams()
  const [loadingList, setLoadingList] = useState(false)
  const [linkpdf, setLinkpdf] = useState("");
  const Noti = new Notification()
  const [loaded, setLoaded] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const iframeRef = useRef(null);
  const { rmaId } = jwtDecode(props?.token ?? token)

  const fnPostSendAcceptance = () => {
    setLoadingList(true)
    const data = { rmaId: rmaId }
    postSendAcceptance(
      {},
      data
    )
      .then(() => {
        setLoadingList(false)
        Noti.success({
          message: 'Success',
          description: `The documents have been successfully sent to email ${props?.values?.staffEmail}. Please proceed with signing the documents.`,
          onOk: () => {
            props?.onSendToSign()
          }
        })
      })
      .catch(error => {
        console.error(error)
        setLoadingList(false)
        Noti.error({
          message: 'Error',
          description: error?.response?.data?.resultDescription
        })
      })
  }

  const reloadIframe = () => {
    if (iframeRef.current) {
      if(loaded || !iframeRef?.current?.contentDocument) return
      iframeRef.current.src += "";
      setAttemptCount(attemptCount + 1);
    }
  };

  useEffect(() => {
    if (!loaded) {
      setLinkpdf(`https://docs.google.com/gview?url=${props.pdf}&embedded=true`)
      const intervalId = setInterval(reloadIframe, 1500);
      return () => clearInterval(intervalId);
    }
  }, [loaded, attemptCount]); // Depend on the loaded state and attemptCount

  return (<>
    <div style={{
      height: 'calc(100vh - 15rem)',
    }}>
    <div style={{
      WebkitOverflowScrolling:"touch",
      height:"100%"
    }}>
      { loaded ? <></> : <Spin className='spin-loading'></Spin> }
      <iframe
        ref={iframeRef}
        title='pdf'
        src={linkpdf}
        style={{
          width: '100%',
          height: '100%',
          border: 0,
        }}
        onLoad={() => {
          setLoaded(true);
      }}
      />

    </div>
      
      <Divider />
      <div style={{
        display: 'flex',
        gap: '1rem'
      }}>
        <Button
          type='default'
          block
          data-testid='back'
          onClick={() => { props.onClickView() }}
          loading={loadingList}
        >
          Back
        </Button>
        {(!props?.isSigned && !props?.isGen && props?.values?.acceptanceStatus != 2) &&
          <Button
            type='primary'
            block
            data-testid='sentToSign'
            onClick={() => { fnPostSendAcceptance() }}
            loading={loadingList}
          >
            Send to sign
          </Button>
        }
      </div>
    </div>
  </>)
}

export default App