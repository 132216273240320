/* istanbul ignore file */
import React, { useEffect, useState, useRef } from 'react'
import {
  Button,
  Row,
  Col,
  Steps,
  Skeleton,
  Breadcrumb,
  Divider,
  Modal,
  Input
} from 'antd'
import {
  postUpdateChecklist,
  postUpdateChecklistComment,
  postSerialNumberSyncMDM
} from '../../services/UnitInstallation'
import {
  getDetail,
  getDetailTree,
  postUpdateStatus,
  putUpdateComment,
  getDropdownInvestigate
} from '../../services/Investigation'
import Notification from '../../services/Notification'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import TreePage from '../checkbox-tree'
import { Helmet } from 'react-helmet'
import { itemRender } from '../../utils/common'
import { jwtDecode } from 'jwt-decode'


const findChildren = (tree, code, pass = false) => {
  let children = []
  for (let i = 0; i < tree.length; i++) {
    /* istanbul ignore else */
    if (tree[i].code !== code && pass && (!tree[i].children || tree[i].children.length === 0)) {
      children.push(tree[i].code)
    }
    /* istanbul ignore else */
    if (tree[i].children && tree[i].children.length > 0) {
      children = [...children, ...findChildren(tree[i].children, code, tree[i].code === code || pass)]
    }
  }
  return children
}

const App = (props) => {
  const initialized = useRef(false)
  const [steps, setSteps] = useState(0)
  const { token } = useParams()
  let { unitId } = useParams()
  const [dataDetail, setDataDetail] = useState({})
  const [dataDetailTree, setDataDetailTree] = useState([])
  const [status, setStatus] = useState('')
  const [isDone, setIsDone] = useState(false)
  const [isDoneDropdown, setIsDoneDropdown] = useState(false)
  const [dropdownValue, setDropdownValue] = useState({})
  const [loading, setLoading] = useState(false)
  const [loadingList, setLoadingList] = useState(false)
  const navigate = useNavigate()
  const [loadingFirst, setLoadingFirst] = useState(true)
  const [dataHeader, setDataHeader] = useState([])
  const [dataDetailItem, setDataDetailItem] = useState({})
  const [loadingSmallComment, setLoadingSmallComment] = useState(false)
  const Noti = new Notification()
  const { email } = jwtDecode(props?.token ?? token)
  const items = [{}, {}, {}]
  const [tel, setTel] = useState('')
  // const [descriptionText, setDescriptionText] = useState('')
  const [openModalCall, setOpenModalCall] = useState(false)
  const [openModalReplacement, setOpenModalReplacement] = useState(false)
  const [showTree, setShowTree] = useState(false)
  const { TextArea } = Input;
  const [pageType, setPageType] = useState("")
  const [dropdownList, setDropdownList] = useState({})
  const [showbtn, setShowBtn] = useState(false)
  const location = useLocation();
  const pathname = location.pathname;

  unitId = props?.unitId ?? unitId

  useEffect(/* istanbul ignore next */() => {
    /* istanbul ignore else */
    if (!initialized.current) {
      if (pathname?.toLowerCase()?.startsWith('/package')) {
        setPageType("package")
      } else if (pathname?.toLowerCase()?.startsWith('/investigate')) {
        setPageType("investigate")
      }
      fnGetDetail()
      initialized.current = true
    }
  })

  const fnGetDetail = () => {
    getDetail({}, unitId).then((res) => {
      const resData = res.data
      /* istanbul ignore else */
      const items = resData.resultData
      setDataDetailItem(items)
      setDataDetail(items)
      switch (items?.status?.toLowerCase()) {
        case 'planned':
          setSteps(0)
          setStatus('Planned')
          break
        case 'in progress':
          setSteps(1)
          fnGetDetailTree()
          fnGetDropdown()
          setStatus('In Progress')
          break
        case 'checklist done':
          setSteps(2)
          fnGetDetailTree()
          fnGetDropdown()
          setStatus('Checklist Done')
          break
        case 'contact helpdesk':
          setSteps(1)
          fnGetDetailTree()
          fnGetDropdown()
          setStatus('Contact Helpdesk')
          break
        case 'request unit replacement':
          setSteps(2)
          fnGetDetailTree()
          fnGetDropdown()
          setStatus('Request Unit replacement')
          break
        case 'completed':
          setSteps(3)
          fnGetDetailTree()
          fnGetDropdown()
          setStatus('Completed')
          break
        default:
          navigate(`/${pageType == "investigate" ?  "investigate" : "package"}/${token}`)
          break
      }

      if((items?.issueType && items?.issueType != null) || items?.issueType == 0){
        setDropdownValue({
          issueType: items?.issueType,
          summaryIssue: items?.summaryIssue,
          nextAction: items?.nextAction,
          identify: items?.pleaseIdentify,
          description: items?.description
        })
      }
      setLoadingFirst(false)
    }).catch((error) => {
      console.log(error)
      Noti.error({
        message: 'Error',
        description: error?.response?.data?.resultDescription
      })
      setLoadingFirst(false)
    })
  }

  const fnGetDetailTree = () => {
    setLoadingList(true)
    getDetailTree({}, unitId).then((res) => {
      const r = res.data
      /* istanbul ignore else */
      const items = r

      const defaultExpanded = items.defaultExpanded || []
      const tree = items.resData || []

      const temp = []
      for (let i = 0; i < defaultExpanded.length; i++) {
        let children = findChildren(tree, defaultExpanded[i])
        temp.push({ key: defaultExpanded[i], children: children })
      }
      setDataHeader(temp)
      setDataDetailTree(items)
      setIsDone(items.isDone)
      setLoadingList(false)
    }).catch((error) => {
      console.log(error)
      Noti.error({
        message: 'Error',
        description: error?.response?.data?.resultDescription
      })
      setLoading(false)
    })
  }

  const fnGetDropdown = () => {
    if(!pathname?.toLowerCase()?.startsWith('/investigate')) return
    setLoadingList(true)
    getDropdownInvestigate({}).then((res) => {
      setDropdownList(res.data.resultData)
      setLoadingList(false)
    }).catch((error) => {
      console.log(error)
      Noti.error({
        message: 'Error',
        description: error?.response?.data?.resultDescription
      })
      setLoading(false)
      setLoadingList(false)
    })
  }

  const fnPutUpdateComment = (e) => {
    setLoading(true)
    const data = {
      comment: e,
      email: email
    }
    putUpdateComment(data, unitId)
      .then(res => {
        fnGetDetailTree()
        setLoading(false)
      }).catch(error => {
        Noti.error({
          message: 'Error',
          description: error?.response?.data?.resultDescription
        })
        setLoading(false)
      })
  }

  const fnPostUpdateChecklistComment = (e, checklistId) => {
    setLoading(true)
    setLoadingSmallComment(true)
    const data = {
      checklistId: checklistId,
      comment: e,
      email: email
    }
    postUpdateChecklistComment(data)
      .then(res => {
        fnGetDetailTree()
        setLoading(false)
        setLoadingSmallComment(false)
      }).catch(error => {
        Noti.error({
          message: 'Error',
          description: error?.response?.data?.resultDescription
        })
        setLoadingSmallComment(false)
        setLoading(false)
      })
  }

  const fnPostUpdateChecklist = (data, check) => {
    setLoading(true)
    let body = {
      checklistId: data,
      flag: check
    }
    postUpdateChecklist(body)
      .then(res => {
        fnGetDetailTree()
        setLoading(false)
      }).catch(error => {
        Noti.error({
          message: 'Error',
          description: error?.response?.data?.resultDescription
        })
        setLoading(false)
      })
  }

  const fnPostUpdateStatus = (data) => {
    postUpdateStatus(data)
      .then(res => {
        fnGetDetail()
      }).catch(error => {
        Noti.error({
          message: 'Error',
          description: error?.response?.data?.resultDescription
        })
      })
  }

  const onCall = () => {
    window.open(`tel:${tel}`, '_self')
    setOpenModalCall(false)
    onSubmit('Contact Helpdesk')
  }

  const fnDropdownChange = (e) => {
    setDropdownValue(e)
    if(e?.issueType === null || e?.issueType === "" || e?.issueType === undefined || e?.summaryIssue === null || e?.summaryIssue === "" || e?.summaryIssue === undefined || e?.nextAction === null || e?.nextAction === "" || e?.nextAction === undefined){
      setIsDoneDropdown(false)
      return
    }

    let d = dropdownList?.nextAction.find(x => x?.name?.toLowerCase() == "others")
    let u = dropdownList?.nextAction.find(x => x?.name?.toLowerCase() == "unit replacement")
    if(e?.nextAction == u.id){
      setShowBtn(true)
    }else{
      setShowBtn(false)
    }
    if(d && d.id == e?.nextAction && (e?.identify === null || e?.identify === "" || e?.identify === undefined)){
      setIsDoneDropdown(false)
      return
    }
    if(u && u.id == e?.nextAction && (e?.description === null || e?.description === "" || e?.description === undefined)){
      setIsDoneDropdown(false)
      return
    }

    setIsDoneDropdown(true)
  }

  const onSubmit = (currentStatus) => {
    // No flow
    /* istanbul ignore else */
    if (steps === 1 && currentStatus.toLowerCase() === 'contact helpdesk' && status.toLowerCase() === 'contact helpdesk') {
      return
    }

    /* istanbul ignore else */
    if (
      (steps === 0 && status.toLowerCase() === 'planned') ||
      (steps === 1 && status.toLowerCase() === 'in progress') ||
      (steps === 1 && status.toLowerCase() === 'contact helpdesk')||
      (steps === 1 && status.toLowerCase() === 'request unit replacement')
    ) {
      const data = {
        poToShyftLineId: unitId,
        status: currentStatus
      }
      // if(currentStatus.toLowerCase() === 'request unit replacement' && descriptionText)
      //   data.description = descriptionText

      if(dataDetail.mdmRegister && dataDetail.mdmRegister.toLowerCase() == "not complete" && currentStatus && currentStatus == "Checklist Done"){
        postSerialNumberSyncMDM({serialNo: dataDetail.serialNumber})
        .then(res => {
          fnPostUpdateStatus(data)
        }).catch(error => {
          Noti.error({
            message: 'Error',
            description: error?.response?.data?.resultDescription
          })
        })
      }else{
        if(pageType == "investigate"){
          data.issueType = dropdownValue.issueType
          data.summaryIssue = dropdownValue.summaryIssue
          data.nextAction = dropdownValue.nextAction
          if(dropdownValue.identify != "")
            data.identify = dropdownValue.identify
          if(dropdownValue.description != "")
            data.description = dropdownValue.description
        }
        fnPostUpdateStatus(data)
      }

    } else if (
      (steps === 0 && status?.toLowerCase() === 'in progress') ||
      (steps === 0 && status?.toLowerCase() === 'contact helpdesk') ||
      (steps === 0 && status?.toLowerCase() === 'checklist done') ||
      (steps === 0 && status.toLowerCase() === 'request unit replacement') ||
      (steps === 0 && status?.toLowerCase() === 'completed')
    ) {
      if (status.toLowerCase() === 'completed') {
        fnGetDetailTree()
        setSteps(2)

      } else {
        fnGetDetail()
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>[NodesNow] {pageType == "investigate" ?  "Investigate" : "Package"}</title>
      </Helmet>
      <div className='main-container'>
        <div className={`public-form`}>
          <div className='card-main'>
            <Breadcrumb
              itemRender={itemRender}
              items={[
                {
                  href: `/${pageType == "investigate" ?  "investigate" : "package"}/${token}`,
                  title: (
                    <>
                      <span>{pageType == "investigate" ?  "Investigate" : "Package"}</span>
                    </>
                  ),
                },
                {
                  title: (
                    <>
                      <span>{pageType == "investigate" ?  "Investigate" : "Package"} Detail</span>
                    </>
                  ),
                }
              ]}
            />
            <Divider plain></Divider>
            {loadingFirst && <Skeleton active />}
            {!loadingFirst && <>
              <div className='product-header'>
                <div className='product-name'>{dataDetailItem.serialNumber}</div>
                <div className='product-detail'>{dataDetailItem.productName}</div>
              </div>
              <div className='step-container'>
                <div className='nn-steps-current'>{status}</div>
                <div className='nn-steps-view'>
                  <Steps
                    size='small'
                    current={steps}
                    items={items}
                    direction='horizontal'
                    responsive={false}
                  />
                </div>
              </div>
              {steps === 0 && <>
                <div className='ui-form'>
                  <div className='detail-container'>
                    <div className='card-container' style={{ border: '10px solid #ededed', padding: 0 }}>
                      <div className='card'>
                        <Row>
                          <Col className='planned-container'>
                            <div className='bold'>Product:</div>
                            <div className='indent'>{dataDetail.productName || '-'}</div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className='planned-container'>
                            <div className='bold'>Serial Number :</div>
                            <div className='indent'>{dataDetail.serialNumber || '-'}</div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className='planned-container'>
                            <div className='bold'>Device Name :</div>
                            <div className='indent'>{dataDetail.deviceName || '-'}</div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className='planned-container'>
                            <div className='bold'>Model Name :</div>
                            <div className='indent'>{dataDetail.modelName || '-'}</div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className='planned-container'>
                            <div className='bold'>MDM Register :</div>
                            <div className='indent'>{dataDetail.mdmRegister || '-'}</div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className='planned-container'>
                            <div className='bold'>Wifi Mac Address :</div>
                            <div className='indent'>{dataDetail.wiFiMacAddress || '-'}</div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className='planned-container'>
                            <div className='bold'>Ethernet Mac Address :</div>
                            <div className='indent'>{dataDetail.ethernetMacAddress || '-'}</div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className='planned-container'>
                            <div className='bold'>{pageType == "investigate" ?  "Issues " : ""}Detail :</div>
                            <div className='indent'>{dataDetail.issuesDescription || '-'}</div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='btn-group-stop'>
                  <Button
                    type='default'
                    block
                    data-testid='backStep'
                    onClick={() => {
                      navigate(`/${pageType == "investigate" ?  "investigate" : "package"}/${token}`)
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    type='primary'
                    block
                    loading={false}
                    data-testid='nextStep'
                    onClick={() => {
                      onSubmit('In Progress')
                    }}
                  >
                    Next Step
                  </Button>
                </div>
              </>}
              {steps === 1 && <>
                <TreePage
                  data={dataDetailTree}
                  onCheck={fnGetDetailTree}
                  loading={loading || loadingList}
                  loadingSmallComment={loadingSmallComment}
                  updateChecklist={fnPostUpdateChecklist}
                  dataHeader={dataHeader}
                  postComment={fnPutUpdateComment}
                  postSmallComment={fnPostUpdateChecklistComment}
                  showNodeTitle={false}
                  disabled={status?.toLowerCase() === 'checklist done'}
                  pageType={pageType}
                  dropdownList={dropdownList}
                  onDropdownChange={fnDropdownChange}
                  dropdownValue={dropdownValue}
                />
                
                <div className='btn-group-stop'>
                  {pageType !== 'investigate' || (pageType === 'investigate' && !showbtn) ? <Button
                    className='done-btn'
                    type='primary'
                    block
                    loading={loading || loadingList}
                    disabled={!isDone || loading || (pageType === 'investigate' && !isDoneDropdown)}
                    data-testid='checklistDone'
                    onClick={() => {
                      onSubmit('Checklist Done')
                    }}
                  >
                    Checklist Done
                  </Button> : <></>}
                  {(pageType === 'investigate' && showbtn) ? <Button
                    className='replace-btn'
                    type='danger'
                    block
                    loading={loading || loadingList}
                    disabled={loading || (pageType === 'investigate' && !isDoneDropdown)}
                    data-testid='replacement'
                    onClick={() => {
                      // setOpenModalReplacement(true)
                      onSubmit('Request Unit replacement')
                    }}
                  >
                    Unit Replacement
                  </Button>: <></>}
                </div>
                
                <div className='btn-group-stop'>
                  <Button
                      type='default'
                      block
                      data-testid='backStep'
                      onClick={() => {
                        setSteps(0)
                        setDataDetail(dataDetailItem)
                      }}
                    >
                      Back
                  </Button>
                </div>
              </>}
              {steps === 2 && <>
                <TreePage
                  data={dataDetailTree}
                  onCheck={fnGetDetailTree}
                  loading={loading || loadingList}
                  loadingSmallComment={loadingSmallComment}
                  updateChecklist={fnPostUpdateChecklist}
                  dataHeader={dataHeader}
                  postComment={fnPutUpdateComment}
                  postSmallComment={fnPostUpdateChecklistComment}
                  disabled={true}
                  showNodeTitle={false}
                  pageType={pageType}
                  dropdownList={dropdownList}
                  onDropdownChange={fnDropdownChange}
                  dropdownValue={dropdownValue}
                />
                <div className='btn-group-stop'>
                  <Button
                    type='default'
                    block
                    data-testid='backStep'
                    onClick={() => {
                      setSteps(0)
                      setDataDetail(dataDetailItem)
                    }}
                  >
                    Back
                  </Button>
                </div>
                
              </>}
              {steps === 3 && <>
                {showTree ? <>
                  <TreePage
                    data={dataDetailTree}
                    onCheck={fnGetDetailTree}
                    loading={loading || loadingList}
                    loadingSmallComment={loadingSmallComment}
                    updateChecklist={fnPostUpdateChecklist}
                    dataHeader={dataHeader}
                    postComment={fnPutUpdateComment}
                    postSmallComment={fnPostUpdateChecklistComment}
                    disabled={true}
                    showNodeTitle={false}
                    pageType={pageType}
                    dropdownList={dropdownList}
                    onDropdownChange={fnDropdownChange}
                    dropdownValue={dropdownValue}
                  />
                  <div className='btn-group-stop'>
                    <Button
                      type='default'
                      block
                      data-testid='backStep'
                      onClick={() => {
                        setShowTree(false)
                      }}
                    >
                      Back
                    </Button>
                  </div>
                  </> : <>
                    <div className='ui-form'>
                      <div className='detail-container'>
                        <div className='card-container' style={{ border: '10px solid #ededed', padding: 0 }}>
                          <div className='card'>
                            <Row>
                              <Col className='planned-container'>
                                <div className='bold'>Product:</div>
                                <div className='indent'>{dataDetail.productName || '-'}</div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className='planned-container'>
                                <div className='bold'>Serial Number :</div>
                                <div className='indent'>{dataDetail.serialNumber || '-'}</div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className='planned-container'>
                                <div className='bold'>Device name :</div>
                                <div className='indent'>{dataDetail.deviceName || '-'}</div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className='planned-container'>
                                <div className='bold'>Model name :</div>
                                <div className='indent'>{dataDetail.modelName || '-'}</div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className='planned-container'>
                                <div className='bold'>Wifi Mac Address :</div>
                                <div className='indent'>{dataDetail.wiFiMacAddress || '-'}</div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className='planned-container'>
                                <div className='bold'>Ethernet Mac Address :</div>
                                <div className='indent'>{dataDetail.ethernetMacAddress || '-'}</div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className='planned-container'>
                                <div className='bold'>{pageType == "investigate" ?  "Issues " : ""}Detail :</div>
                                <div className='indent'>{dataDetail.issuesDescription || '-'}</div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='btn-group-stop'>
                      <Button
                        type='default'
                        block
                        data-testid='backStep'
                        onClick={() => {
                          navigate(`/${pageType == "investigate" ?  "investigate" : "package"}/${token}`)
                        }}
                      >
                        Back
                      </Button>
                      <Button
                        type='primary'
                        block
                        loading={false}
                        data-testid='nextStep'
                        onClick={() => {
                          setShowTree(true)
                        }}
                      >
                        Next Step
                      </Button>
                    </div>
                </>}
              </>}
            </>}
          </div>
        </div>
      </div>
      <Modal
        open={openModalCall}
        title='Confirmation'
        closeIcon={false}
        okText='Call'
        onOk={() => onCall()}
        okButtonProps={{
          style: {
            backgroundColor: '#34DD5A'
          },
          'data-testid': 'call'
        }}
        onCancel={() => setOpenModalCall(false)}
        cancelButtonProps={{
          style: {
            backgroundColor: '#FE0000',
            borderColor: 'transparent',
            color: '#fff'
          }
        }}
        footer={(_, { OkBtn, CancelBtn }) => (<>
          <CancelBtn />
          <OkBtn />
        </>)}
      >
        <span>Are you sure you want to call the helpdesk?</span>
        <br />
        <input value={tel} onChange={e => { setTel(e.target.value) }} />
      </Modal>
      {/* <Modal
        open={openModalReplacement}
        title='Description'
        closeIcon={false}
        okText='Confirm'
        onOk={() => {setOpenModalReplacement(false);onSubmit('Request Unit replacement')}}
        okButtonProps={{
          'data-testid': 'replacement',
          'disabled': descriptionText ? false : true
        }}
        onCancel={() => {setDescriptionText("");setOpenModalReplacement(false)}}
        footer={(_, { OkBtn, CancelBtn }) => (<>
          <CancelBtn />
          <OkBtn />
        </>)}
      >
      <TextArea value={descriptionText} onChange={e => { setDescriptionText(e.target.value) }} rows={4} placeholder="Type your unit replacement description."/>
      </Modal> */}
    </>
  )
}

export default App