/* istanbul ignore file */
import React, { useState, useEffect, useRef } from 'react'
import Layout from '../../component/layout/Layout'
import { Helmet } from 'react-helmet'
import SkeletonLoading from '../../component/SkeletonLoading'
import { DetailColumns } from '../../component/table/AssignUnitInstallation'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Row,
  Table,
} from 'antd'
import { useParams, useLocation } from 'react-router'
import { postExport } from '../../services/TdsSaasUnitDelivery'
import dayjs from 'dayjs'
import fileDownload from 'js-file-download'
import { itemRender } from "../../utils/common"
import {
  DownloadOutlined
} from '@ant-design/icons'
import Notification from '../../services/Notification'

const App = (props) => {
  const [form] = Form.useForm()
  const initialized = useRef(false)
  const [loadingFirst, setLoadingFirst] = useState(true)
  const [dataList, setDataList] = useState([])
  const { uuid } = useParams()
  let { poId, packageId } = useParams()
  const { state } = useLocation()
  const Noti = new Notification()

  poId = atob(props?.poId ?? poId)
  packageId = props?.packageId ?? packageId

  const itemMenu = [
    {
      href: `/assign-unit-replacement/${uuid}/${btoa(poId)}`,
      title: (
        <>
          <span>Assign Unit Installation</span>
        </>
      )
    },
    {
      title: (
        <>
          <span>Assign Unit Installation Detail</span>
        </>
      )
    }
  ]

  useEffect(/* istanbul ignore next */() => {
    /* istanbul ignore else */
    if (!initialized.current) {
      initialized.current = true
      setLoadingFirst(false)
      const childs = state?.dataUnit?.map((c, i) => {
        c.seqNo = i + 1
        return c
      })
      setDataList(childs ?? [])
    }
  }, [state])

  const fnPostExport = () => {
    let body = {
      unitReplacementId: poId
    }

    postExport(
      { Authorization: `Bearer ${state.token}` },
      body
    ).then((res) => {
      /* istanbul ignore else */
      if (res.status === 200) {
        let fileName = `Report_TDS_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`
        fileDownload(res.data, fileName)
      }
    }).catch((error) => {
      console.error(error)
      Noti.error({
        message: 'Error',
        description: error?.response?.data?.resultDescription
      })
    })
  }

  return (
    <Layout activeKey='assign-unit-installation'>
      <Helmet>
        <title>[NodesNow] Unit Delivery</title>
      </Helmet>
      {loadingFirst ? <SkeletonLoading /> : <>
        <Breadcrumb
          itemRender={itemRender}
          items={itemMenu}
        />
        <Divider plain></Divider>
        <Card title='Assign Unit Installation' style={{ textAlign: 'left' }}>
          <Form
            form={form}
            onFinish={(value) => {
            }}
            initialValues={{}}
            size={'default'}
            labelCol={{ flex: '150px' }}
            labelAlign='left'
            labelWrap
            wrapperCol={{ flex: 1 }}
            colon={false}
          >
            <Row gutter={8}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className='col-style'>
                <Form.Item
                  label='REP Number* :'
                  name='poId'
                  rules={[]}
                  colon={true}
                  className='custom-label'
                >
                  {poId}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className='col-style'>
                <Form.Item
                  label='Purchase Orders'
                  name='ptId'
                  rules={[]}
                  colon={true}
                  className='custom-label'
                >
                  <div>
                    {packageId}
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '1rem'
          }}>
            <Button
              type='primary'
              block
              loading={false}
              onClick={() => {
                fnPostExport()
              }}
              style={{ width: 200 }}
              icon={<DownloadOutlined />}
            >
              Export File
            </Button>
          </div>
          <Table
            key={'1'}
            rowKey={record => record.key}
            bordered
            showSorterTooltip={false}
            scroll={{ x: 300 }}
            columns={[
              ...DetailColumns
            ]}
            locale={{ emptyText: <div > No Data </div> }}
            pagination={false}
            dataSource={dataList}
            size='small'
          />
        </Card>
      </>}
    </Layout>
  )
}

export default App