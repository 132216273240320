/* istanbul ignore file */
import { post, put, get } from './RequestApi'

export function getList(params) {
  return get({ url: `/unit-installation`, params })
}

export function getDetail(data, id) {
  return get({ url: `/unit-installation/${id}`, data })
}

export function getDetailTree(data, id) {
  return get({ url: `/unit-installation/checklist/${id}`, data })
}

export function postUpdateStatus(data) {
  return post({ url: `/unit-installation/status`, data })
}

export function postUpdateChecklist(data) {
  return post({ url: `/unit-installation/checklist`, data })
}

export function putUpdateComment(data, id) {
  return put({ url: `/unit-installation/comment/${id}`, data })
}

export function postUpdateChecklistComment(data) {
  return post({ url: `/unit-installation/checklist/comment`, data })
}

export function postUpdateStatusCustomerDelivery(headers, data) {
  return post({ url: `/tds/purchase-order/customer-delivery/status`, headers, data })
}

export function postUpdateCustomerDelivery(headers, data, id) {
  return post({ url: `/tds/purchase-order/customer-delivery/${id}`, headers, data })
}

export function postGenerateAcceptance(headers, data) {
  return post({ url: `/tds/purchase-order/customer-delivery/acceptance/generate`, headers, data })
}

export function postPreviewAcceptance(headers, data) {
  return post({ url: `/tds/purchase-order/customer-delivery/acceptance/preview`, headers, data })
}

export function getCustomerDelivery(params, id) {
  return get({ url: `/tds/purchase-order/customer-delivery/${id}`, params })
}

export function postSendAcceptance(headers, data) {
  return post({ url: `/tds/purchase-order/customer-delivery/acceptance/send`, headers, data })
}

export function postCreateReplacementUnit(headers, data) {
  return post({ url: `/replacement/create`, headers, data })
}

export function postGenerateRMA(headers, data) {
  return post({ url: `/warehouse-partner/rma/generate`, headers, data })
}

export function getUnitReplacement(headers, id) {
  return get({ url: `/replacement/${id}`, headers})
}

export function postSerialNumberSyncMDM(data) {
  return post({ url: `/stock/serial-number/sync-mdm`, data })
}