import React, { useEffect, useState, useRef } from 'react'
import {
  Divider,
  Button,
  Table,
  Skeleton,
  Row,
  Col,
} from 'antd'
import { useParams } from 'react-router-dom'
import { getList, getListRma } from '../../services/Warehouse'
import { jwtDecode } from 'jwt-decode'
import IconDocument from '../../assets/ic-document.png'
import Notification from '../../services/Notification'
import dayjs from 'dayjs'
// import TableLoading from '../../component/table/Loading'
// import _ from 'lodash'


const App = (props) => {
  const initialized = useRef(false)
  const [dataRma, setDataRma] = useState([])
  const dataList = useRef([])
  const [loadingList, setLoadingList] = useState(false)
  const [dis, setDis] = useState(false)
  const [rmaNo, setRmaNo] = useState("")
  const [seq, setSeq] = useState("")
  const Noti = new Notification()
  const { token } = useParams()
  const { rmaId, actionType } = jwtDecode(props?.token ?? token)

  useEffect(/* istanbul ignore next */() => {
    /* istanbul ignore else */
    if (!initialized.current) {
      initialized.current = true

      load()
    }
  })

  const load = async () => {
    setLoadingList(true)
    try {
      await fnGetList()
      
    } catch (error) {
      console.error(error)
      setLoadingList(false)
    }
  }

  const fnGetList = () => {
    if(actionType == 1){
      return new Promise((resolve, reject) => {
        getList({}, rmaId)
          .then((res) => {
            let { resData } = res?.data
            
            let done = resData.rmawhLineList.map((item, index) => ({ ...item, no: index + 1 }))
            setDataRma(resData)
            setSeq(resData.seqNo)
            if(resData?.rmaNo) setRmaNo(resData?.rmaNo)
            props.seq(resData.seqNo)
            dataList.current = done ?? []
  
            setLoadingList(false)
          }).catch((error) => {
            console.error(error)
            Noti.error({
              message: 'Error',
              description: error?.response?.data?.resultDescription
            })
            setLoadingList(false)
            reject()
          })
      })
    }else if(actionType == 2){
      return new Promise((resolve, reject) => {
        getListRma({}, rmaId)
          .then((res) => {
            let { resData } = res?.data
            
            let done = resData.serialNumberList.map((item, index) => ({ ...item, no: index + 1 }))
            if(resData.status != 3) setDis(true)
            setDataRma(resData)
            setSeq(resData.seqNo)
            props.seq(resData.seqNo)
            dataList.current = done ?? []
  
            setLoadingList(false)
          }).catch((error) => {
            console.error(error)
            Noti.error({
              message: 'Error',
              description: error?.response?.data?.resultDescription
            })
            setLoadingList(false)
            reject()
          })
      })
    }

  }

  return (<>
    {loadingList && [{}, {}, {}].map((r, i) => {
      return <div className={`card-li card`} key={'loadingList_' + i}>
        <Row>
          <Col flex='70px' style={{ display: 'flex' }}>
            <Skeleton.Button active shape={'circle'} size={'large'} style={{ margin: 'auto' }} />
          </Col>
          <Col flex='auto'>
            <Skeleton.Button active={true} block={true} size={'small'} />
            <Skeleton.Button active={true} block={true} size={'small'} style={{ marginTop: 8 }} />
          </Col>
        </Row>
      </div>
    })}
    {!loadingList && <>
      <div style={{
        color: '#454D58',
        marginBottom: '1rem',
        textAlign: 'center'
      }}>
        Reference : {seq}
      </div>
      {rmaNo && <div style={{
        color: '#454D58',
        textAlign: 'center'
      }}>
        RMA Number : {rmaNo}
      </div>}
      <Divider plain></Divider>
      <Row>
        <Col className='planned-container'>
          <div className='bold'>{dataRma.actionType == 1 ? "Pickup Details" : "Deliver Details"}</div>
          <div className='indent'> Estimated delivery date : {dataRma.estimatedDate ? dayjs(dataRma.estimatedDate).format('DD/MM/YYYY') : '-'}</div>
        </Col>
      </Row>
      <Row>
        <Col className='planned-container'>
          <div className='bold'>Customer Address</div>
          <div className='indent'>Property Name : {dataRma.propertyName || '-'}</div>
          <div className='indent'>Address : {dataRma.contactAddress || '-'}</div>
        </Col>
      </Row>
      <Row>
        <Col className='planned-container'>
          <div className='bold'>Customer Contact</div>
          <div className='indent'>Name : {dataRma.contactName || '-'}</div>
          <div className='indent'>Phone : {dataRma.contactPhone || '-'}</div>
          <div className='indent'>E-mail : {dataRma.contactEmail || '-'}</div>
        </Col>
      </Row>
      {dataList.current.length > 0 && <>
        <h4>Device List</h4>
        <Table
          key={'1'}
          rowKey={record => record.key}
          bordered
          showSorterTooltip={false}
          scroll={{ x: 300 }}
          columns={[
            {
              title: 'No.',
              dataIndex: 'no',
              align: 'left',
              key: 'no',
              sorter: false,
              width: 100,
              render: (data) => <>{data}</>,
            },
            {
              title: 'Serial Number',
              dataIndex: 'serialNumber',
              align: 'left',
              key: 'serialNumber',
              sorter: false,
              render: (data) => <>{data}</>,
            },
            ...(actionType == 1
              ? [
                  {
                    title: 'Description',
                    dataIndex: 'description',
                    align: 'left',
                    key: 'description',
                    sorter: false,
                    render: (data) => <>{data}</>,
                  },
                ]
              : []),
          ]}
          locale={{ emptyText: <div>No Data</div> }}
          pagination={false}
          dataSource={dataList.current}
          size="small"
        />
      </>}

      {dataList.current.length > 0 &&
        <div style={{width:"100%", textAlign: 'center',marginTop:"20px"}}>
          <Button
            disabled={dis}
            type='primary'
            block
            data-testid='installationCompleted'
            onClick={() => {
              props.onAcceptance()
            }}
            loading={loadingList}
            // onClick={() => { onClickInstallationCompleted() }}
            // disabled={
            //   dataList.length === 0 ||
            //   loadingList ||
            //   !_.every(dataList, { status: 'Checklist Done' })
            // }
            >
            Acceptance Document
          </Button>
        </div>
      }
      
    </>}
  </>)
}

export default App