import React, { useEffect, useState, useRef } from 'react'
import {
  Form,
  Modal,
  Row,
  Col,
  Divider,
  Breadcrumb,
  Skeleton,
  Button
} from 'antd'
import FloatInput from '../../component/FloatInput'
import logoNN from '../../assets/nn1.png'
import scanIcon from '../../assets/scan-qrcode.png'
import checkIcon from '../../assets/check.png'
import cancelIcon from '../../assets/cancel.png'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom'
import { getList, postUpdateStatusCustomerDelivery, postAcceptanceDetail } from '../../services/Investigation'
import { postCreateReplacementUnit } from '../../services/UnitInstallation'
import Notification from '../../services/Notification'
import { SearchOutlined } from '@ant-design/icons'
import { itemRender } from '../../utils/common'
import { Helmet } from 'react-helmet'
import { Html5Qrcode } from 'html5-qrcode'
import { jwtDecode } from 'jwt-decode'
import InvestigationAcceptance from './investigation-acceptance'
import InvestigationUnitReplacement from './investigation-unit-replacement'
import _ from 'lodash'
import dayjs from 'dayjs'
import InvestigationPreview from './investigation-preview'

const p1 = `The installation is completed. To validate this process, the client must sign the document in the next step. Please provide the information to proceed.`
const p2 = `The installation is complete. The Acceptance document has been signed and received.`
const p3 = `The installation is complete. Currently, the acceptance document is being signed.`
const p4 = `The on-site investigation is completed. To validate this process, the client must sign the document in the next step. Please provide the information to proceed.`
const statusObj = {
  0 : "Draft",
  1 : "Planned",
  2 : 'In progress',
  3 : 'Done',
}

const App = (props) => {
  const [form] = Form.useForm()
  const initialized = useRef(false)
  const [cloneList, setCloneList] = useState([])
  const [dataList, setDataList] = useState([])
  const [dataMain, setDataMain] = useState({})
  const navigate = useNavigate()
  const [loadingList, setLoadingList] = useState(false)
  const [openCamera, setOpenCamera] = useState(false)
  const [showDeliAccep, setShowDeliAccep] = useState(false)
  const [showUnitReplacement, setShowUnitReplacement] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [pdf, setPdf] = useState(null)
  const [isView, setIsView] = useState(false)
  const [isGen, setIsGen] = useState(false)
  const [isSigned, setIsSigned] = useState(false)
  const [viewData, setViewData] = useState(null)
  const [status, setStatus] = useState(null)
  const [values, setValues] = useState(null)
  const [rmaData, setRmaData] = useState([])
  const html5QrCode = useRef()
  const { token } = useParams()
  const [pageType, setPageType] = useState("")
  const location = useLocation();
  const pathname = location.pathname;
  const Noti = new Notification()
  const {
    investigateId,
    poToShyftId
  } = jwtDecode(props?.token ?? token)

  useEffect(() => {
    if (!initialized.current) {
      if (pathname?.toLowerCase()?.startsWith('/package')) {
        setPageType("package")
      } else if (pathname?.toLowerCase()?.startsWith('/investigate')) {
        setPageType("investigate")
      }
      onLoad()
      initialized.current = true
    }
  })

  const onLoad = async () => {
    setLoadingList(true)
    try {
      // const getCustomerDeliveryRes = await fnGetCustomerDelivery()
      // setStatus(getCustomerDeliveryRes?.status)
      await fnGetList()
      // if (statusObj[getCustomerDeliveryRes?.status] !== statusObj[14]) {
      //   if (statusObj[getCustomerDeliveryRes.status] === statusObj[12]) {
      //     setIsGen(true)
      //   }
      //   if (statusObj[getCustomerDeliveryRes.status] === statusObj[13]) {
      //     setIsSigned(true)
      //   }
      //   if (statusObj[getCustomerDeliveryRes?.status] !== statusObj[2] &&
      //     statusObj[getCustomerDeliveryRes?.status] !== statusObj[3] &&
      //     statusObj[getCustomerDeliveryRes?.status] !== statusObj[9]) {
      //   }
      // }
      setLoadingList(false)
    } catch (error) {
      console.error(error)
      setLoadingList(false)
    }
  }

  const fnGetList = () => {
    return new Promise((resolve, reject) => {
      getList({}, poToShyftId)
        .then((res) => {
          let { resultData } = res?.data
          setCloneList(resultData.poToShyftLineList ?? [])
          setDataList(resultData.poToShyftLineList ?? [])
          setDataMain(resultData ?? {})
          setStatus(resultData.status)
          resolve()
        }).catch((error) => {
          console.error(error)
          Noti.error({
            message: 'Error',
            description: error?.response?.data?.resultDescription
          })
          reject()
        })
    })
  }

  const startScan = async () => {
    await setOpenCamera(true)
    const qrboxFunction = function (viewfinderWidth, viewfinderHeight) {
      let minEdgePercentage = 0.85 // 70%
      let minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight)
      let qrboxSize = Math.floor(minEdgeSize * minEdgePercentage)
      return {
        width: qrboxSize,
        height: qrboxSize
      }
    }
    html5QrCode.current = new Html5Qrcode('reader')
    Html5Qrcode.getCameras().then(devices => {
      console.log(devices)
      if (devices && devices.length) {
        const config = { fps: 10, qrbox: qrboxFunction }
        html5QrCode.current.start(
          // cameraId,     // retreived in the previous step.
          { facingMode: 'environment' },
          config,
          qrCodeMessage => {
            // do something when code is read. For example:
            html5QrCode.current.stop().then(async ignore => {
              // QR Code scanning is stopped.                
              setOpenCamera(false)
              form.setFieldValue('serialNumber', qrCodeMessage)
              let res = cloneList.filter(x => x.serialNumber.toLowerCase().includes(qrCodeMessage.toLowerCase()))
              setDataList(res)
              html5QrCode.current = null
            }).catch(err => {
              // Stop failed, handle it.
              console.log('Unable to stop scanning.')
            })

          },
          errorMessage => {
            // parse error, ideally ignore it. For example:
          })
          .catch(err => {
            // Start failed, handle it. For example,
            console.log(`Unable to start scanning, error: ${err}`)
          })
        // console.log('🚀 ~ file: installation.js:45 ~ Html5Qrcode.getCameras ~ cameraId:', cameraId)
        // .. use this to start scanning.
      }
    }).catch(err => {
      // handle err
    })
  }

  const stopScan = () => {
    html5QrCode.current.stop().then(ignore => {
      html5QrCode.current = null
      // QR Code scanning is stopped.
      console.log('QR Code scanning stopped.')
    }).catch(err => {
      // Stop failed, handle it.
      console.log('Unable to stop scanning.')
    })
    setOpenCamera(false)
  }

  const onClickInstallationCompleted = async () => {
    try {
      setLoadingList(true)
      const data = {
        poToShyftId : poToShyftId,
        status: 'Done'
      }
      if(investigateId)
        data.investigateId = investigateId

      let replaceArr = cloneList.filter(x=> x.status.toLowerCase() === "request unit replacement")
      if(replaceArr.length > 0){
        let createRe = {
          poToShyftId : poToShyftId,
          replacementList:[]
        }
        for (let i = 0; i < replaceArr.length; i++) {
          createRe.replacementList.push({
            serialNumber : replaceArr[i].serialNumber,
            description : replaceArr[i].unitReplacementDescription || null
          })
        }
        await postCreateReplacementUnit({}, createRe)
      }
      await postUpdateStatusCustomerDelivery({ Authorization: `Bearer ${token}` }, data)
      setLoadingList(false)
      form.resetFields()
      if(pageType == "package"){
        await fnGetList()
      }else if(pageType == "investigate"){
        setShowUnitReplacement(true)
      }
    } catch (error) {
      console.error(error)
      setLoadingList(false)
      Noti.error({
        message: 'Error',
        description: error?.response?.data?.resultDescription
      })  
    }
  }

  const onShowPreview = (data) => {
    console.log("🚀 ~ onShowPreview ~ data:", data)
    setPdf(`${data?.file?.url}?time=${dayjs().format('YYYYMMDDHHmmss')}`)
    setValues(data?.values)
    setShowDeliAccep(false)
    setShowPreview(true)
  }

  const onClickView = async (data = {}) => {
    try {
      setLoadingList(true)
      const getAcceptance = await fnGetAcceptance()
      setViewData({
        ...getAcceptance,
      })
      setLoadingList(false)
      setShowPreview(false)
      setShowDeliAccep(true)
      setIsView(data?.isView === undefined ? false : true)
    } catch (error) {
      setLoadingList(false)
      console.error(error)
    }
  }

  const fnGetAcceptance = () => {
    return new Promise((resolve, reject) => {
      let searchId = {}
      if(investigateId){
        searchId.investigateId = investigateId
      }else{
        searchId.poToShyftId = poToShyftId
      }
      postAcceptanceDetail({ Authorization: `Bearer ${token}` }, searchId)
        .then((res) => {
          const { resData } = res.data
          resolve(resData)
        }).catch((error) => {
          console.error(error)
          Noti.error({
            message: 'Error',
            description: error?.response?.data?.resultDescription
          })
          reject()
        })
    })
  }

  const onSendToSign = () => {
    onLoad()
    setShowPreview(false)
  }

  return (<>
    <Helmet>
      <title>[NodesNow] {pageType == "investigate" ?  "Investigate" : "Package"}</title>
    </Helmet>
    <div className='main-container'>
      <div className='public-form'>
        {!showPreview && <>
          <div className='card-main'>
            <Breadcrumb
              itemRender={itemRender}
              items={[
                {
                  href: `/${pageType == "investigate" ?  "investigate" : "package"}`,
                  title: (
                    <>
                      <span>{pageType == "investigate" ?  "Investigate" : "Package"}</span>
                    </>
                  ),
                },
              ]}
            />
            <Divider plain></Divider>
            <div className='logo-container'>
              <img src={logoNN} className='img-logo' alt=''></img>
            </div>
              {dataMain?.seqNo && <div style={{
                color: '#454D58',
                marginBottom: '1rem',
                textAlign: 'center'
              }}>
                Purchase Orders : {dataMain?.seqNo}
              </div>}
              {dataMain?.investigateNo && pageType == "investigate" && <div style={{
                color: '#454D58',
                marginBottom: '1rem',
                textAlign: 'center'
              }}>
                Investigate : {dataMain?.investigateNo}
              </div>}
              {!showDeliAccep && !showUnitReplacement && <>
                <div className='search-container'>
                  <Form
                    form={form}
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    layout='vertical'
                    initialValues={{}}
                    size={'default'}
                    style={{
                      width: '100%',
                      margin: 'auto',
                    }}
                  >
                    <Form.Item name='serialNumber'>
                      <FloatInput
                        label='Input Serial Number'
                        placeholderInput='Input Serial Number'
                        placeholder='Input Serial Number'
                        name='Input Serial Number'
                        style={{ borderRadius: '10px' }}
                        onKeyUp={(e) => {
                          let res = cloneList.filter(x => x.serialNumber.toLowerCase().includes(e.target.value.toLowerCase()))
                          setDataList(res)
                        }}
                        suffix={
                          <span
                            data-testid='submit'
                            onClick={(e) => {
                              let res = cloneList.filter(x => x.serialNumber.toLowerCase().includes(e.target.value.toLowerCase()))
                              setDataList(res)
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <SearchOutlined />
                          </span>
                        }
                      />
                    </Form.Item>
                  </Form>
                  <img
                    src={scanIcon}
                    alt=''
                    style={{
                      width: '40px',
                      height: '40px',
                      cursor: 'pointer',
                      marginLeft: '5px',
                    }}
                    onClick={startScan}
                  ></img>
                </div>
                <div className='list-container'>
                  <div className='card-container'>
                    {loadingList && [{}, {}, {}].map((r, i) => {
                      return <div className={`card-li card`} key={'loadingList_' + i}>
                        <Row>
                          <Col flex='70px' style={{ display: 'flex' }}>
                            <Skeleton.Button active shape={'circle'} size={'large'} style={{ margin: 'auto' }} />
                          </Col>
                          <Col flex='auto'>
                            <Skeleton.Button active={true} block={true} size={'small'} />
                            <Skeleton.Button active={true} block={true} size={'small'} style={{ marginTop: 8 }} />
                          </Col>
                        </Row>
                      </div>
                    })}
                    {!loadingList && <>
                      {dataList.length === 0 ? <div style={{ display: 'none' }}>Data not found</div> : null}
                      {dataList.map((r, i) => {
                        return (
                          <div
                            key={'dataList_' + i}
                            className={
                              `card-li card custom ${r.status.toLowerCase() === 'planned' ? 'planned' : ''}${r.status.toLowerCase() === 'in progress' ? 'in-progress' : ''}${r.status.toLowerCase() === 'checklist done' ? 'checklist-done' : ''}${r.status.toLowerCase() === 'contact helpdesk' ? 'contact-helpdesk' : ''}${r.status.toLowerCase() === 'request unit replacement' ? 'unit-replacement' : ''}${r.status.toLowerCase() === 'completed' ? 'installation-completed' : ''}`
                            }
                          >
                            <Row
                              style={{ alignItems: 'center', flexWrap: 'nowrap', justifyContent: "space-between" }}
                              onClick={() => {
                                navigate(`/${pageType == "investigate" ?  "investigate" : "package"}-detail/${token}/${r.poToShyftLineId}`)
                              }}
                              data-testid={'installation_row_' + i}
                            >
                              <Col>
                                <div className='serial-container'>
                                  <span style={{ display: 'none' }}>_SerialNumber</span>
                                  <span>Serial Number</span>
                                  <span>{r.serialNumber || '-'}</span>
                                </div>
                                <div className='regis-container'>
                                  {r.mdmRegister == "Completed" &&
                                  <>
                                    <img src={checkIcon} alt='' style={{ width: '15px', height: '15px'}}></img>
                                    <span>Registered</span>
                                  </>}
                                  {r.mdmRegister == "Not complete" &&
                                  <>
                                    <img src={cancelIcon} alt='' style={{ width: '15px', height: '15px'}}></img>
                                    <span>Not Registered</span>
                                  </>}
                                </div>
                              </Col>
                              <Col>
                                <div className='status-container'>
                                  <span>{r.status}</span>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )
                      })}
                    </>}
                  </div>
                </div>
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '2rem'
                }}>
                  {[statusObj[3]].includes(statusObj[status]) && pageType != "package" && 
                    // 3: 'Done' if done show
                    <Button
                      type='primary'
                      block
                      data-testid='next'
                      onClick={() => { form.resetFields();setShowUnitReplacement(true) }}
                      disabled={
                        cloneList.length === 0 ||
                        loadingList
                      }
                      loading={loadingList}
                    >
                      Next
                    </Button>}
                  {![statusObj[3]].includes(statusObj[status]) &&
                    // 3: 'Done' if not done show
                    <Button
                      type='primary'
                      block
                      data-testid='installationCompleted'
                      onClick={() => { onClickInstallationCompleted() }}
                      disabled={
                        cloneList.length === 0 ||
                        loadingList ||
                        !_.every(cloneList, item => item.status.toLowerCase() === 'checklist done' || item.status.toLowerCase() === "request unit replacement")
                      }
                      loading={loadingList}
                    >
                      Completed
                    </Button>}
                </div>
              </>}
              {showUnitReplacement && <>
                <InvestigationUnitReplacement
                  rma={rmaData}
                  onBack={() => {
                    setShowUnitReplacement(false)
                    onLoad()
                  }}
                  onAcceptance={() => {
                    setShowUnitReplacement(false)
                    onClickView({ isView: true })
                  }}
                />
              </>}
              {showDeliAccep && <>
                <InvestigationAcceptance
                  token={props?.token ?? token}
                  onShowPreview={onShowPreview}
                  isView={isView}
                  isGen={isGen}
                  isSigned={isSigned}
                  viewData={viewData}
                  onBack={() => {
                    setShowDeliAccep(false)
                    setShowUnitReplacement(true)
                  }}
                  p={pageType == "investigate" ? p4 : p1}
                />
              </>}
          </div>
        </>}
        {showPreview && <>
          <InvestigationPreview
            pdf={pdf}
            values={values}
            onClickView={onClickView}
            onSendToSign={onSendToSign}
            isGen={isGen}
            isSigned={isSigned}
          />
        </>}
      </div>
    </div>
    <Modal
      width='90%'
      style={{ maxWidth: '750px' }}
      open={openCamera}
      title='Scan QR Code'
      onCancel={stopScan}
      footer={(_, { OkBtn, CancelBtn }) => (<></>)}
    >
      <div id='reader'></div>
    </Modal>
  </>)
}

export default App