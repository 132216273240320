/* istanbul ignore file */
import { post, put, get } from './RequestApi'

export function getList(params, id) {
  return get({ url: `/installation-partner/${id}`, params })
}

export function getDetail(data, id) {
  return get({ url: `/installation-partner/detail/${id}`, data })
}

export function getDetailTree(data, id) {
  return get({ url: `/installation-partner/checklist/${id}`, data })
}

export function postUpdateStatus(data) {
  return post({ url: `/installation-partner/status`, data })
}

export function putUpdateComment(data, id) {
  return put({ url: `/installation-partner/comment/${id}`, data })
}

export function postUpdateStatusCustomerDelivery(headers, data) {
  return post({ url: `/installation-partner/po-to-shyft/status`, headers, data })
}

export function postAcceptanceDetail(headers, data) {
  return post({ url: `/acceptance/detail`, headers, data })
}

export function postAcceptance(headers, data) {
  return post({ url: `/acceptance`, headers, data })
}

export function postGenerateAcceptance(headers, data) {
  return post({ url: `/acceptance/generate`, headers, data })
}

export function postPreviewAcceptance(headers, data) {
  return post({ url: `/acceptance/preview`, headers, data })
}

export function postSendAcceptance(headers, data) {
  return post({ url: `/acceptance/send`, headers, data })
}

export function getDropdownInvestigate(params) {
  return get({ url: `/installation-partner/investigate/dropdownlist`, params })
}