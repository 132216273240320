/* istanbul ignore file */
import React, { useEffect, useState, useRef } from 'react'
import {
  Button,
  Card,
  Row,
  Col,
  Skeleton,
  Form,
  Input,
  Tooltip
} from 'antd'
import { ReactGrid } from '@silevis/reactgrid'
import { useParams } from 'react-router-dom'
import fileDownload from 'js-file-download'
import { Helmet } from 'react-helmet'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import _ from 'lodash'

import '../component/reactgrid/styles.scss'
import Icon, {
  DownloadOutlined,
  SaveOutlined,
  EditOutlined,
  CloseOutlined,
  CloudSyncOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined
} from '@ant-design/icons'
import Notification from '../services/Notification'
import {
  putUpdateReplacementUnitDetail,
  postPurchaseOrderDetail,
  postMacAddress,
  getSerialNumber,
  putUpdateNewUnitDetail,
  postExport,
  postVerifyPurchaseOrder
} from '../services/TdsSaasUnitDelivery'
import Layout from '../component/layout/Layout'
import SkeletonLoading from '../component/SkeletonLoading'
import { GridDropdownCellTemplate } from '../component/rateCell/GridDropdownCellTemplate'
import { GridDropdownCellTemplate2 } from '../component/rateCell/GridDropdownCellTemplate2'
import { HTMLHeaderCellTemplate } from '../component/rateCell/HTMLHeaderCellTemplate'
import { GridDateTimeCellTemplate } from '../component/rateCell/GridDateTimeCellTemplate'
import { applyChangesToData } from '../utils/common'
import { jwtDecode } from 'jwt-decode'

dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.tz.setDefault('America/New_York')

const HEADING_ROW_HEIGHT = 40
const headerRow = {
  height: HEADING_ROW_HEIGHT,
  rowId: 'header',
  cells: [
    { type: 'customHeader', text: "No.", nonEditable:true },
    { type: 'customHeader', text: 'Part', nonEditable:true },
    { type: 'customHeader', text: 'Serial Number', nonEditable:true },
    { type: 'customHeader', text: 'Device Type', nonEditable:true },
    { type: 'customHeader', text: 'Installation Date', nonEditable:true },
    { type: 'customHeader', text: 'Delivery Date', nonEditable:true },
    { type: 'customHeader', text: 'Package Tracking Number', nonEditable:true },
    { type: 'customHeader', text: 'Logistic Company', nonEditable:true },
    { type: 'customHeader', text: 'MDM Register', nonEditable:true },
    { type: 'customHeader', text: 'Ethernet Mac Address', nonEditable:true },
    { type: 'customHeader', text: 'Wifi Mac Address', nonEditable:true },
  ]
}

const getColumns = () => [
  { columnId: 'no', width: 70, isDisabled: true },
  { columnId: 'part', width: 400 },
  { columnId: 'serialNumber', width: 180 },
  { columnId: 'deviceType', width: 240 },
  { columnId: 'installationDate', width: 240 },
  { columnId: 'deliveryDate', width: 240 },
  { columnId: 'packageTrackingNumber', width: 200 },
  { columnId: 'logisticCompany', width: 150 },
  { columnId: 'mdmRegister', width: 150 },
  { columnId: 'ethernetMacAddress', width: 180 },
  { columnId: 'wiFiMacAddress', width: 180 },
]

const App = (props) => {
  const [form] = Form.useForm()
  const initialized = useRef(false)
  let { uuid } = useParams()
  const [editMode, setEditMode] = useState(false)
  const [hideEdit, setHideEdit] = useState(false)
  const [loadingFirst, setLoadingFirst] = useState(true)
  const [dataItem, setDataItem] = useState([])
  const cloneItem = useRef([])
  const [ddlSerial, setDdlSerial] = useState([])
  const [logingApi, setLogingApi] = useState(false)
  const [logingMacApi, setMacLogingApi] = useState(false)
  const [verifySuccess, setVerifySuccess] = useState(false)
  const [warehousePartnerId, setWarehousePartnerId] = useState('')
  const [loadingApi, setLoadingApi] = useState(false)
  const [onNotiError, setOnNotiError] = useState(false)
  const userToken = useRef(false)
  const Noti = new Notification()

  uuid = props?.uuid ?? uuid

  useEffect(/* istanbul ignore next */() => {
    /* istanbul ignore else */
    if (!initialized.current) {
      initialized.current = true
      setLoadingFirst(false)
    }
  }, [])

  const fnGetSerialNumber = () => {
    setLogingApi(true)
    getSerialNumber({}).then(res => {
      setLoadingFirst(false)
      const { resData } = res.data
      /* istanbul ignore else */
      setDdlSerial(resData)
      setLoadingFirst(false)
      setLogingApi(false)
    }).catch(error => {
      console.error(error)
      setWarehousePartnerId('')
      setVerifySuccess(false)
      setLoadingFirst(false)
      setLogingApi(false)
      if(!onNotiError)
        Noti.error({
          message: 'Error',
          description: error?.response?.data?.resultDescription,
          onCancel: () => {
            setOnNotiError(false)
          }
        })
      setOnNotiError(true)
    })
  }

  const fnPostPurchaseOrderDetail = () => {
    setLogingApi(true)
    postPurchaseOrderDetail(
      { Authorization: `Bearer ${userToken.current}` },
      {},
      uuid
    ).then(res => {
      setLoadingFirst(false)
      const { resData } = res.data
      /* istanbul ignore else */
      let rawData = resData?.customer_delivery ?? []
      let newData = []
      let allData = 0
      rawData.forEach(r => {
        r?.unit_delivery?.forEach(rr => {
          newData.push(rr)
        })
      })
      newData = newData.map(r => {
        /* istanbul ignore else */
        if (r?.serialNumber) allData++
        return {
          part: r?.part ?? '',
          serialNumber: r?.serialNumber ?? '',
          replacementBy: r?.replacementBy ?? '',
          installationDate: r?.installationDate ? dayjs(r.installationDate).$d : null,
          deliveryDate: r?.deliveryDate ? dayjs(r.deliveryDate).$d : null,
          packageTrackingNumber: r?.packageTrackingNumber ?? '',
          mdmRegister: r?.mdmRegister ?? '',
          logisticCompany: r?.logisticCompany ?? '',
          ethernetMacAddress: r?.ethernetMacAddress ?? '',
          wiFiMacAddress: r?.wiFiMacAddress ?? ''
        }
      })
      newData = _.orderBy(newData, 'serialNumber')

      /* istanbul ignore else */
      if (allData === newData.length){
        setHideEdit(true)
      }else{
        fnGetSerialNumber()
      }
      setDataItem(newData)
      cloneItem.current = JSON.parse(JSON.stringify(newData))
      setLoadingFirst(false)
      setLogingApi(false)
    }).catch(error => {
      console.error(error)
      setWarehousePartnerId('')
      setVerifySuccess(false)
      setLoadingFirst(false)
      setLogingApi(false)
      if(!onNotiError)
        Noti.error({
          message: 'Error',
          description: error?.response?.data?.resultDescription,
          onCancel: () => {
            setOnNotiError(false)
          }
        })
      setOnNotiError(true)
    })
  }

  const fnPostVerifyPurchaseOrder = (id) => {
    let body = {
      uuid: uuid,
      refId : id
    }
    
    setLoadingApi(true)
    postVerifyPurchaseOrder(body).then(res => {
      const { resultData } = res.data
      /* istanbul ignore else */
      userToken.current = resultData.userToken
      setVerifySuccess(true)
      fnPostPurchaseOrderDetail()
      setLoadingApi(false)
    }).catch(error => {
      if(!onNotiError)
      Noti.error({
        message: 'Error',
        description: error?.response?.data?.resultDescription,
        onCancel: () => {
          setOnNotiError(false)
          setLoadingApi(false)
        }
      })
      setOnNotiError(true)
      setWarehousePartnerId('')
    })
  }

  const fnPostExport = () => {
    const { unit_replacement_id, rma_wh_id } = jwtDecode(userToken.current)
    let body = {}
    if(unit_replacement_id){
      body.unitReplacementId = warehousePartnerId
    }else if(rma_wh_id){
      body.warehousePartnerId = warehousePartnerId
    }

    postExport(
      { Authorization: `Bearer ${userToken.current}` },
      body
    ).then(res => {
      /* istanbul ignore else */
      let fileName;
      if(unit_replacement_id){
        fileName = `Report_replacement_order_${warehousePartnerId}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`
      }else if(rma_wh_id){
        fileName = `Report_delivered_unit_${warehousePartnerId}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`
      }
      fileDownload(res.data, fileName)
    }).catch(error => {
      console.error(error)
      if(!onNotiError)
        Noti.error({
          message: 'Error',
          description: error?.response?.data?.resultDescription,
          onCancel: () => {
            setOnNotiError(false)
          }
        })
      setOnNotiError(true)
    })
  }

  const fnPutUpdatePurchaseOrderDetail = () => {
    setLogingApi(true)
    const tempArr = []
    for (let i = 0; i < dataItem.length; i++) {
      let temp = dataItem[i]
      /* istanbul ignore else */
      if (temp?.installationDate)
        temp.installationDate = dayjs(temp.installationDate).isValid() ? dayjs(temp.installationDate).$d : null

      if (temp?.deliveryDate)
        temp.deliveryDate = dayjs(temp.deliveryDate).isValid() ? dayjs(temp.deliveryDate).$d : null
        
      /* istanbul ignore else */
      if (temp.serialNumber)
        tempArr.push(temp)
      }
    const { unit_replacement_id, rma_wh_id } = jwtDecode(userToken.current)

    if(unit_replacement_id){
      putUpdateReplacementUnitDetail(
        { Authorization: `Bearer ${userToken.current}` },
        tempArr,
        uuid
      ).then(() => {
        /* istanbul ignore else */
        Noti.success({
          message: 'Success',
          description: 'You data has been saved successfully.'
        })
        setEditMode(false)
        setLogingApi(false)
        fnPostPurchaseOrderDetail()
      }).catch(error => {
        console.log(error)
        if(!onNotiError)
          Noti.error({
            message: 'Error',
            description: error?.response?.data?.resultDescription,
            onCancel: () => {
              setOnNotiError(false)
            }
          })
        setOnNotiError(true)
        setLogingApi(false)
      })
    }else if(rma_wh_id){
      putUpdateNewUnitDetail(
        { Authorization: `Bearer ${userToken.current}` },
        tempArr,
        uuid
      ).then(() => {
        /* istanbul ignore else */
        Noti.success({
          message: 'Success',
          description: 'You data has been saved successfully.'
        })
        setEditMode(false)
        setLogingApi(false)
        fnPostPurchaseOrderDetail()
      }).catch(error => {
        console.log(error)
        if(!onNotiError)
          Noti.error({
            message: 'Error',
            description: error?.response?.data?.resultDescription,
            onCancel: () => {
              setOnNotiError(false)
            }
          })
        setOnNotiError(true)
        setLogingApi(false)
      })
    }


  }

  const disabledSave = () => {
    let isDisabled = false
    let count = 0
    for (let i = 0; i < dataItem.length; i++) {
      let item = dataItem[i]
      /* istanbul ignore else */
      if (cloneItem.current[i].serialNumber) continue
      /* istanbul ignore else */
      if (
        item.serialNumber &&
        item.packageTrackingNumber &&
        item.logisticCompany &&
        item.mdmRegister !== ''
      ) {
        count++
        continue
      }
      /* istanbul ignore else */
      if (
        !item.serialNumber &&
        !item.packageTrackingNumber &&
        !item.logisticCompany &&
        !item.mdmRegister === ''
      ) {
        isDisabled = false
      } else {
        isDisabled = true
        break
      }
    }
    if (count >= 1 && !isDisabled) {
      isDisabled = false
    } else if (count >= 1 && isDisabled) {
      isDisabled = true
    }
    /* istanbul ignore else */
    if (count === 0) isDisabled = true

    return isDisabled
  }

  const onSave = () => {
    fnPutUpdatePurchaseOrderDetail()
  }

  const dataItemTemp = dataItem.map((r, i) => {
    return { no: `${(i + 1)}`, ...r }
  })

  const _editMode = logingApi ? false : editMode

  const getRows = (rows) => [
    headerRow,
    ...rows.map((row, i) => {
      const isEditable = (field) => !_editMode || cloneItem.current[i][field] ? true : false;
      const getStyle = (field) => _editMode && !cloneItem.current[i][field] ? {} : { background: '#eeeeee' };
  
      const serialNumberCell = cloneItem.current[i].serialNumber ? {
        type: 'text',
        text: row?.serialNumber ?? '',
        nonEditable: isEditable('serialNumber'),
        style: getStyle('serialNumber')
      } : {
        type: 'scdropdown2',
        value: row?.serialNumber ?? '',
        values: [
          { value: '', label: '' },
          ...ddlSerial.map((x) => (
            { value: x.serialNumber, label: x.serialNumber }
          ))
        ],
        isOpened: row?.isOpened,
        nonEditable: isEditable('serialNumber'),
        style: getStyle('serialNumber')
      };
  
      return {
        rowId: i,
        height: HEADING_ROW_HEIGHT,
        cells: [
          {
            type: 'text',
            text: row?.no ?? '',
            nonEditable: true,
            style: { background: '#eeeeee' }
          },
          {
            type: 'text',
            text: row?.part ?? '',
            nonEditable: true,
            style: { background: '#eeeeee' }
          },
          serialNumberCell,
          {
            type: 'text',
            text: row?.replacementBy ?? '',
            nonEditable: true,
            style: {
              background: '#eeeeee',
              color: (row?.replacementBy ?? "").toLowerCase() === "new unit" ? "red" : "#000"
            }
          },
          {
            type: 'scdatetime',
            value: row?.installationDate ?? '',
            nonEditable: isEditable('installationDate'),
            style: getStyle('installationDate')
          },
          {
            type: 'scdatetime',
            value: row?.deliveryDate ?? '',
            nonEditable: isEditable('deliveryDate'),
            style: getStyle('deliveryDate')
          },
          {
            type: 'text',
            text: row?.packageTrackingNumber ?? '',
            nonEditable: isEditable('packageTrackingNumber'),
            style: getStyle('packageTrackingNumber')
          },
          {
            type: 'scdropdown',
            value: row?.logisticCompany ?? '',
            values: [
              { value: '', label: '' },
              { value: 'DHL', label: 'DHL' },
              { value: 'Other', label: 'Other' }
            ],
            isOpen: row?.isOpen,
            nonEditable: isEditable('logisticCompany'),
            style: getStyle('logisticCompany')
          },
          {
            type: 'text',
            text: row?.mdmRegister ?? '',
            nonEditable: true,
            style: { background: '#eeeeee' }
          },
          {
            type: 'text',
            text: row?.ethernetMacAddress ?? '',
            nonEditable: true,
            style: { background: '#eeeeee' }
          },
          {
            type: 'text',
            text: row?.wiFiMacAddress ?? '',
            nonEditable: true,
            style: { background: '#eeeeee' }
          }
        ]
      }
    }),
  ];

  const rows = getRows(dataItemTemp)

  const columns = getColumns()

  const handleChanges = (changes) => {
    console.log("handleChanges", changes)
    let isDup = false
    for (let i = 0; i < changes.length; i++) {
      if(changes[i].type == "customHeader") return
      /* istanbul ignore else */
      // if (changes[i].columnId === 'deliveryDate' || changes[i].columnId === 'installationDate') {
      //   changes[i].newCell.value = dayjs(changes[i].newCell.date).format('.format("YYYY-MM-DD HH:mm")')
      //   /* istanbul ignore else */
      //   if (!changes[i].newCell.value) {
      //     changes[i].newCell.date = ''
      //   }
      // }
      /* istanbul ignore else */
      if (changes[i].columnId === 'packageTrackingNumber' && changes[i].newCell.text) {
        changes[i].newCell.text = changes[i].newCell.text.replaceAll('\r', '')
        /* istanbul ignore else */
      }
      if (changes[i].columnId === 'serialNumber') {
        let a = dataItem
        if((!(changes[i].newCell.text && changes[i].newCell.text != ""))){
          a[changes[i].rowId].replacementBy = null
          a[changes[i].rowId].mdmRegister = null
          a[changes[i].rowId].wiFiMacAddress = null
          a[changes[i].rowId].ethernetMacAddress = null
          a[changes[i].rowId].deviceName = null
          a[changes[i].rowId].modelName = null
          a[changes[i].rowId].deviceId = null
          setDataItem(a)
          continue
        }
        if(changes[i].previousCell.text == changes[i].newCell.text) continue

        let f = dataItem.find((x) => (x && x.serialNumber) === changes[i].newCell.text)
        /* istanbul ignore else */
        if (f) {
          changes[i].newCell.text = changes[i].previousCell.text
          changes[i].newCell.value = changes[i].previousCell.text
          if(isDup) continue
          if(!onNotiError)
            Noti.error({
              message: 'Error',
              description: 'Serial Number is duplicated',
              onCancel: () => {
                setOnNotiError(false)
              }
            })
          isDup = true
          setOnNotiError(true)
        }else{
          let type = ddlSerial.find((x) => (x && x.serialNumber) === changes[i].newCell.text)
          if(type){
            a[changes[i].rowId].replacementBy = type.replacementBy
            a[changes[i].rowId].mdmRegister = type.mdmRegister
            a[changes[i].rowId].wiFiMacAddress = type.wiFiMacAddress
            a[changes[i].rowId].ethernetMacAddress = type.ethernetMacAddress
            a[changes[i].rowId].deviceName = type.deviceName
            a[changes[i].rowId].modelName = type.modelName
            a[changes[i].rowId].deviceId = type.deviceId
          }
          setDataItem(a)
        }
      }
    }
    setDataItem((prevData) => applyChangesToData(changes, prevData))
  }

  return (
    <Layout activeKey='new-unit'>
      <Helmet>
        <title>[NodesNow] New Unit</title>
      </Helmet>
      {
        loadingFirst
          ? <SkeletonLoading />
          : <>
            <Card title="NodesNow Material Acquisition" style={{ textAlign: 'left' }}>
              <Form
                form={form}
                onFinish={(value) => {
                  fnPostVerifyPurchaseOrder(warehousePartnerId)
                }}
                initialValues={{}}
                size={'default'}
                labelCol={{ flex: '150px' }}
                labelAlign='left'
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
              >
                <Row gutter={8}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} className='col-style'>
                    <Form.Item
                      label="Reference ID* :"
                      name='pdId'
                      rules={[]}
                    >
                      <div style={{ display: 'flex', gap: '10px' }}>
                        {/* <label for='txtPoid' style={{ display: 'none' }}>xxx</label> */}
                        <Input
                          style={{ flex: 5 }}
                          value={warehousePartnerId}
                          onChange={(e) => {
                            setWarehousePartnerId(e.target.value)
                          }}
                          disabled={loadingApi || verifySuccess}
                          data-testid='txtPoid'
                          name='pdId'
                        />
                        <Button type='primary' style={{ minWidth: 185 }} className='ant-btn-middle'
                          onClick={() => {
                            form.submit()
                          }}
                          loading={loadingApi}
                          disabled={loadingApi || verifySuccess || !warehousePartnerId}
                          data-testid='submit'
                        >Search</Button>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <div style={{ display: 'none' }}>
                <Button data-testid='test1' onClick={() => {
                  let dt = dataItem
                  dt = dt.map(r => {
                    r.serialNumber = 'NJ23T000004'
                    return r
                  })
                  setDataItem(dt)
                }}> </Button>
              </div>
              {
                verifySuccess
                  ? <Row gutter={8}>
                    {
                      editMode
                        ? <Col
                          xs={24} sm={24} md={24} lg={24} xl={24}
                          className='col-style'
                          style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                            marginTop: '1rem'
                          }}
                        >
                          <div>
                            {/* <Button type='primary' icon={<CloudSyncOutlined />}
                              data-testid='get-mac'
                              onClick={() => {
                                fnPostMacAddress()
                              }} style={{ marginLeft: 4, minWidth: 150, marginTop: 4 }}
                              disabled={logingApi}
                              loading={logingMacApi}
                              className='bt-main'>Get Mac Address</Button> */}
                            <Button type='primary' icon={<SaveOutlined />}
                              data-testid='save'
                              onClick={() => {
                                onSave()
                              }} style={{ marginLeft: 4, minWidth: 150, marginTop: 4 }}
                              disabled={logingMacApi || disabledSave()}
                              loading={logingApi}
                              className='bt-main'>Save</Button>
                            <Button icon={<CloseOutlined />}
                              data-testid='cancel'
                              onClick={() => {
                                setEditMode(false)
                                fnPostPurchaseOrderDetail()
                              }}
                              style={{ marginLeft: 4, minWidth: 150, marginTop: 4 }}
                              disabled={logingApi || logingMacApi}
                              className='bt-main'
                            >Cancel</Button>
                          </div>
                        </Col>
                        : <Col
                          xs={24} sm={24} md={24} lg={24} xl={24}
                          className='col-style'
                          style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                            marginTop: '1rem'
                          }}
                        >
                          <div>
                            <Button type='primary' icon={<DownloadOutlined />}
                              data-testid='download'
                              onClick={() => {
                                fnPostExport()
                              }}
                              style={{ marginLeft: 4, minWidth: 150, marginTop: 4 }}
                              disabled={logingApi}
                              className='bt-main'
                            >Export File</Button>
                            {
                              !hideEdit
                                ? <>
                                  <Button type='primary' icon={<EditOutlined />}
                                    data-testid='edit'
                                    onClick={() => {
                                      setEditMode(true)
                                    }}
                                    style={{ marginLeft: 4, minWidth: 150, marginTop: 4 }}
                                    disabled={logingApi}
                                    className='bt-main'
                                  >Edit</Button>
                                </>
                                : <></>
                            }
                          </div>
                        </Col>
                    }
                  </Row>
                  : <div style={{ display: 'none' }}> not verify </div>
              }
              {
                verifySuccess
                  ? <Card
                    title=''
                    bordered={false}
                    style={{ width: '100%', marginTop: 10 }}
                    className='table-list'
                  >
                    <div style={{
                      display: 'flex',
                      gap: '.5rem',
                      marginBottom: '.5rem'
                    }}>
                      <label>Total unit amount: {dataItem.length} units</label>
                      {editMode && <Tooltip
                        title='You can copy information from excel and paste to this table.'
                        placement='right'
                        overlayInnerStyle={{
                          color: '#000',
                          padding: '1rem',
                          width: '500px'
                        }}
                        color='#ffffff'
                      >
                        <ExclamationCircleOutlined />
                      </Tooltip>
                      }
                    </div>
                    <div style={{
                      display: 'flex',
                      width: '100%',
                      height: '500px',
                      overflow: 'auto'
                    }}>
                      {
                        !logingApi
                          ? <ReactGrid
                            stickyLeftColumns={2}
                            stickyTopRows={1}
                            enableRangeSelection={true}
                            customCellTemplates={{
                              'scdropdown': new GridDropdownCellTemplate(),
                              'scdropdown2': new GridDropdownCellTemplate2(),
                              'scdatetime': new GridDateTimeCellTemplate(),
                              "customHeader" : new HTMLHeaderCellTemplate()
                            }}
                            enableFillHandle={editMode}
                            onCellsChanged={handleChanges}
                            onFocusLocationChanging={(e)=>e.rowId != "header"}
                            rows={rows}
                            columns={columns}
                          />
                          : <Skeleton active />
                      }
                    </div>
                  </Card>
                  : <div style={{ display: 'none' }}>not verify</div>
              }
            </Card>
          </>
      }
    </Layout >
  )
}

export default App